import { FontWeights, getTheme, IconButton, mergeStyleSets, Modal } from "@fluentui/react"
import { ShareWorkflowDialog } from "./ShareWorkflowDialog";
import React from "react";

type TProps = {
    isOpen: boolean
    qrCodeLink?: string
    onDismiss: () => any;
}

export const ShareWorkflowModal: React.FC<TProps> = ({isOpen, qrCodeLink, onDismiss}) => {
return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            containerClassName={shareQrCodeModalStyles.container}
        >
            <div className={shareQrCodeModalStyles.header}>
            <IconButton
                styles={iconButtonStyles}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close"
                onClick={onDismiss}
            />
            </div>
            <div className={shareQrCodeModalStyles.body}>
                <ShareWorkflowDialog qrCodeLink={qrCodeLink ?? ""} />
            </div>
        </Modal> 
    )
}

const theme = getTheme()

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const shareQrCodeModalStyles = mergeStyleSets({
  container: {
    borderRadius: 6,
  },
  header: [
    theme.fonts.large,
    {
      flex: "1 1 auto",
      borderTop: `none`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "0px 0px 0px 12px",
    },
  ],
  body: {
    position: "relative",
    flex: "4 4 auto",
    padding: "0px 24px 24px 24px",
    overflowY: "hidden",
    height: 'auto',
    width: 600,
  },
});
