import { 
    AnimationStyles, DetailsList, DetailsListLayoutMode, 
    DetailsRow, DirectionalHint, getTheme, IColumn, Icon, 
    IDetailsColumnProps, IDetailsHeaderProps, IDetailsListProps, 
    IDetailsRowStyles, IRenderFunction, IStackStyles, ITooltipProps, 
    Link, MessageBar, MessageBarType, SelectionMode, SharedColors, 
    Spinner, Stack, Text, TooltipHost 
} from "@fluentui/react";
import { ServiceTypes } from "@piceasoft/core";
import { strings } from "../../../../../localization/strings"
import React from "react";
import { useSelector } from "react-redux";
import { IStore } from "../../../../../core/store";
import { Experience } from "../../../../../components/shared/configurator/helpers/evisibility";
import { endpoints } from "../../../../../core/api/endpoints";
import { portalApi } from "../../../../../core/api/api";
import { ScreenHeader } from "../../../../../components/shared/decorations/ScreenHeader";
import { SimpleCustomFilter } from "../../../../../components/shared/filter/SimpleCustomFilter";
import { SomethingWentWrong } from "../../../../../components/shared/somethingWentWrong/SomethingWentWrong";
import { onRenderColumnHeaderCommon } from "../../../../../components/shared/detailsList/commonRenders";
import { getIconSymbolsFromString, getPersonaColorsPallete } from "../../../../../core/scripts/style";
import { Badge } from "../../../../../components/shared/decorations/Badge";
import { useId } from "@uifabric/react-hooks";
import QRCode from "react-qr-code";
import { CopyToClipBoardButton } from "../../../../../components/shared/buttons/CopyToClipboardButton";
import { TooltipIconButton } from "../../../../../components/shared/buttons/TooltipIconButton";
import { ShareWorkflowModal } from "../ShareWorkflowModal";


type TProps = {
    serviceId: number;
    channel: Experience;
    serviceType: ServiceTypes;
}

type TStoreItem = {
    id: string;
    name: string;
    code: string;
    assigmentSource: string;
    qrCodeLink?: string;
}

export const ServiceConfigurationStoresPivot: React.FC<TProps> = (props) => {

    const qrcodeInfoTooltipId = useId('qrcode-info-tooltip')

    const [storesState, setStoresState] = React.useState<{ fetched: boolean, data?: TStoreItem[], error?: string }>({ fetched: false })
    const [storeItems, setStoreItems] = React.useState<TStoreItem[]>([])
    const [searchString, setSearchString] = React.useState<string>()
    const applicationUrl = useSelector((s: IStore) => s.environment.applicationUrl)
    const selfServiceUrl = useSelector((s: IStore) => s.environment.selfServiceUrl)
    const [shareWorkflowModalState, setShareWorkflowModalState] = React.useState<{isOpen: boolean, qrCodeLink?: string}>({isOpen: false, qrCodeLink: undefined})

    const qrCodeInfoTooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <div style={{ boxShadow: theme.effects.elevation8, padding: 12, overflow: "hidden" }}>
                <Text>{strings.ORGANIZATION.SERVICES.SERVICE.QR_CODE_INFO}</Text>
            </div>
        ),
    };
    
    const prepareStoreItems = (): TStoreItem[] => {
        const prepareQrCodeLink = (store_code: string) =>
            {
                if (props.channel !== Experience.Mobile)
                    return undefined

                return props.channel === Experience.Mobile && props.serviceType === ServiceTypes.SelfService ?
                    endpoints.external.selfServiceApp(selfServiceUrl, props.serviceId, store_code):
                    undefined
                    //endpoints.external.applicationWidget(applicationUrl, props.serviceId) 
            }

        return (storesState.data ?? []).map((i) => {
            return {
                id: i.id,
                code: i.code,
                name: i.name,
                assigmentSource: i.assigmentSource,
                qrCodeLink: prepareQrCodeLink(i.code)
            }
        })
    }

    const fetchStores = async () => {
        setStoresState({fetched: false})

        const result = await portalApi.organization.services.getServicePoints(props.serviceId)
        
        if (result.successed)
            setStoresState({fetched: true, data: (result.data ?? []).map(p => { return {
                id: p.id,
                name: p.name,
                code: p.code,
                assigmentSource: p.assignmentSource

            }})})
        else 
            setStoresState({fetched: true, error: (result.errors && result.errors.length > 0) ? result.errors[0]?.description : undefined})
    }

    React.useEffect(() => {
        fetchStores()
    }, [])

    React.useEffect(() => {
        if (!storesState.fetched)
            return;

        setStoreItems(prepareStoreItems())

    }, [storesState.data])

    React.useEffect(() => {
        setStoreItems(() => {
            var newItems = storesState.data ? [...storesState.data] : []
            if (searchString) {
                newItems = [
                    ...((newItems as TStoreItem[]).filter(i => {
                        let isFounded = false
                        const originValues = [
                            i.code,
                            i.name
                        ]
                        originValues.map(ov => {
                            if (ov && isFounded !== true) {
                                isFounded = ov.toString().toLowerCase().match(new RegExp(`\w*${searchString?.toLowerCase()}\w*`)) != null
                            }
                        })
                        return isFounded
                    }))
                ]
            }
            return newItems as TStoreItem[]
        })
    }, [searchString])
    
    const onRenderQrCodeHeader: IRenderFunction<IDetailsColumnProps> = (chProps) => {
        if (!chProps) {
            return null;
        }

        return (
            <Stack tokens={{ padding: "0px 32px 0px 0px" }} horizontal>
                <Stack horizontal verticalAlign={"center"}>
                    <Text variant="small" style={{ fontWeight: 600, color: SharedColors.gray30 }}>{chProps.column.name}</Text>
                    <TooltipHost tooltipProps={qrCodeInfoTooltipProps} id={qrcodeInfoTooltipId}>
                        <Icon aria-describedby={qrcodeInfoTooltipId} iconName={'Info'} style={{ fontSize: 14, color: SharedColors.blue10, marginLeft:'1rem' }} />
                    </TooltipHost>
                </Stack>
            </Stack>
        );

    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: strings.ORGANIZATION.STRUCTURE.ITEM.POINT.TABLE_COLUMNS.NAME,
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 400,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderColumnHeaderCommon,
            onRender: (item: TStoreItem) => item.name.length > 0 && onRenderNamePointCell(item),
            isPadded: true,
        },
        {
            key: 'column2',
            name: strings.SERVICES.SERVICE.WORKFLOW_QR_CODE,
            fieldName: 'qrCodeLink',
            minWidth: 150,
            isResizable: false,
            data: 'string',
            styles: { root: { ':hover': { backgroundColor: 'transparent' } } },
            onRenderHeader: onRenderQrCodeHeader,
            onRender: (item: TStoreItem) => {
                if (!item.qrCodeLink) return <></>
                return (
                    <Stack horizontal verticalAlign={"center"}>
                        <TooltipHost tooltipProps={{
                            onRenderContent: () => (
                                <div style={{ boxShadow: theme.effects.elevation8, padding: 12, overflow: "hidden" }}>
                                <QRCode value={ item.qrCodeLink as string}
                                        size={130}
                                        fgColor={SharedColors.gray40} />
                            </div>                            
                            )
                        }}>
                            <Link href={item.qrCodeLink} target="_blank">
                                { (item.qrCodeLink.length > 30) && (item.qrCodeLink.slice(0, 30) + '...') || item.qrCodeLink }
                            </Link>
                        </TooltipHost>
                        <Stack styles={{ root: { maxWidth: 48, paddingLeft:15 } }}>
                            <Stack horizontal verticalAlign={"center"}>
                                <CopyToClipBoardButton
                                    content={item.qrCodeLink}
                                    copyText={strings.BUTTONS.TEXT.COPY_LINK}
                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }} />
                            </Stack>
                        </Stack>
                        <Stack styles={{ root: { maxWidth: 48 } }}>
                            <Stack horizontal verticalAlign={"center"}>
                                <TooltipIconButton 
                                    content={strings.BUTTONS.TEXT.SHARE_WORKFLOW}
                                    tooltipCalloutProps={{ directionalHint: DirectionalHint.rightCenter }}
                                    iconName="Share"
                                    onClick={() => {
                                        setShareWorkflowModalState({isOpen: true, qrCodeLink:item.qrCodeLink})
                                    }}
                                />
                                
                            </Stack>
                        </Stack>                        
                    </Stack>                    
                );
            },
            isPadded: true,
        }      

    ]

    const onRenderNamePointCell = (item: TStoreItem) => {
        return (
            <Stack grow horizontal verticalAlign='center' verticalFill tokens={{ childrenGap: 12 }} >
                <Stack.Item>
                    <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: getPersonaColorsPallete[item.name[0].toLowerCase()] }}>
                        <Text variant="large" style={{ color: "#fff", fontSize: 16, fontWeight: 100, paddingBottom: 2 }}>
                            {getIconSymbolsFromString(item.name)}
                        </Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item grow>
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 600 }}>{item.name}</Text>
                            {item.code && <Badge title={item.code} />}
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>
        )
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = rProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rProps) {
            customStyles.root = {
                cursor: 'pointer',
                ':hover': {
                    '.disabled-icon': {
                        color: theme.palette.black
                    },
                    '.menu-icon': {
                        color: theme.palette.black
                    },
                }
            };
            return <Stack><DetailsRow {...rProps} styles={customStyles} /></Stack>;
        }
        return null;
    };
        
    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }

        return (
            <Stack>
                <Stack.Item>
                    <Stack tokens={{ padding: "16px 20px 4px 20px", childrenGap: 4 }} horizontal horizontalAlign='space-between'>
                        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 4 }}>
                            <Text variant="medium" style={{ fontWeight: 400, color: SharedColors.gray40 }}>{strings.COMMON.TOTAL}</Text>
                            <Stack horizontalAlign='center' verticalAlign='center' style={{ backgroundColor: theme.palette.neutralLight, borderRadius: 12 }}>
                                <Text variant='small' style={{ fontWeight: 600, marginLeft: -1, padding: "2px 8px" }}>{storeItems.length}</Text>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack.Item>
                {defaultRender!({ ...props })}
            </Stack>
        );
    };

    return (
        <Stack verticalFill>
            <Stack.Item verticalFill>
                <Stack verticalFill>
                    {!storesState.error && (storesState.fetched && storesState.data && (
                        <>
                            <ScreenHeader title={strings.ORGANIZATION.SERVICES.SERVICE.USED_BY_STORES}>
                                <SimpleCustomFilter onChange={(ev, value) => setSearchString(value)} />
                            </ScreenHeader>
                            <Stack.Item tokens={{ padding: "0px 32px 24px 32px" }} >
                                <Stack styles={containerStyles}>
                                    <Stack.Item>
                                        <DetailsList
                                            styles={{ root: { width: '100%' } }}
                                            items={storeItems}
                                            columns={columns}
                                            setKey="none"
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                            isHeaderVisible={true}
                                            onRenderRow={onRenderRow}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        </>
                    ) || (
                            <Stack verticalAlign='center' verticalFill horizontalAlign='center' tokens={{ childrenGap: 12 }}>
                                <Spinner label={strings.SPINNERS.DATA_IS_GETTING} />
                            </Stack>
                        ))}
                    {storesState.error && (
                        <Stack verticalFill>
                            {storesState.error.length > 0 && (
                                <MessageBar messageBarType={MessageBarType.error}>{storesState.error}</MessageBar>
                            )}
                            <Stack.Item verticalFill>
                                <SomethingWentWrong action={fetchStores} />
                            </Stack.Item>
                        </Stack>
                    )}
                    <ShareWorkflowModal 
                        isOpen={shareWorkflowModalState.isOpen} 
                        qrCodeLink={shareWorkflowModalState.qrCodeLink} 
                        onDismiss={() => {
                            setShareWorkflowModalState({isOpen: false, qrCodeLink: undefined})
                        }}/>
                </Stack>
            </Stack.Item>        
    </Stack>)
}

const theme = getTheme();

const containerStyles: IStackStyles = {
    root: {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation8,
        borderRadius: theme.effects.roundedCorner4,
        ...AnimationStyles.slideUpIn10,
        overflow: 'hidden',
        position: "relative"
    }
};