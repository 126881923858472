import { Stack, Text, Link, Icon } from "@fluentui/react";
import { SharedColors } from "@fluentui/theme";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { IStore } from "../../../core/store";
import { IdentificationMethods, Inspections, ProcessStages } from "@piceasoft/core";
import { strings } from "../../../localization/strings";

type TProps = {
    /** MAdCap articleid. If not provided, it will be guessed from location.pathname */
    articleid?: string,
    /** Set to 'left' to have left alignment. All other values or if not provide - right alignment  */
    alignment?: string,
    /** custom link text. If not provided - "help" will be used  */
    label?: string
}

/** returns help article id for workflow stages configuration */
export const getHelpArticleIdForStage = (stage: ProcessStages): string => {
    switch (stage) {
        case ProcessStages.Resources: return '1163'
        case ProcessStages.Landing: return '1024'
        case ProcessStages.SelfService: return '1017'
        case ProcessStages.Identification: return '1153'
        case ProcessStages.PreOffer: return '1159'
        case ProcessStages.Assessment: return '1140'
        case ProcessStages.PostOffer: return '1156'
        case ProcessStages.Contract: return '1160'
        case ProcessStages.Result: return '1143'
        
        case ProcessStages.ContentTransfer: return '1119'
        
        default: return '1128'
    }
}

    /** get content-sensitive help article id for identification module settings*/
export const getHelpArticleIdForIdentificationModule = (identificationMethod: IdentificationMethods ): string => {
        switch (identificationMethod) {
                case IdentificationMethods.PiceaOne: return '1152';
                case IdentificationMethods.PiceaUsb: return '1151';
                case IdentificationMethods.SearchForm: return '1155'; 
                case IdentificationMethods.Global: return '1148'; 
                case IdentificationMethods.Universal: return '1147'; 
                case IdentificationMethods.Imei: return '1150'; 
                case IdentificationMethods.Scanner: return '1146'; 
                case IdentificationMethods.Transaction: return '1154'; 
                default: return '1149';
            }

        }

    /** get content-sensitive help article id for inspection module settings*/
export const getHelpArticleIdForInspectionModule = (inspection: Inspections ): string => {
        switch (inspection) {
                case  Inspections.Software: return '1142';
                case  Inspections.Diagnostics: return '1137';
                case  Inspections.AIGrading: return '1130';
                case  Inspections.Interview: return '1132';
                case  Inspections.Photographic: return '1139';
                case  Inspections.DataCollection: return '1133';
                case  Inspections.Erase: return '1138';
                case Inspections.ManualGrading: return '1191';
                //case  Inspections.Contract: return ''
                default: return '1134';
                }
        }


/** chows link to content-sensitive help article */
export const CSHHelpLink: React.FC<TProps> = (props) => {
    var articleid: string = '-100'; //leads to documentation start page

    const storeHelpArticleId = useSelector((s: IStore) => s.help?.helpArticleId) ?? 'undefined'
    
    if (props.articleid) { //if articleid is provided in props, use it directly
        articleid = props.articleid;
    } else {
        const location = useLocation().pathname; //getting location to understand where user is in UI
        switch(true) {
            //transactions
            case (location.startsWith('/overview/transactions')): articleid = '1117'; break;
            case (location.startsWith('/overview/moderations')): articleid = '1116'; break;
            //customer
            case (location.startsWith('/organization/structure')): articleid = '1104'; break;
            case (location.startsWith('/organization/companies')): articleid = '1100'; break;
            case (location.startsWith('/organization/responsible')): articleid = '1103'; break;
            case (location.startsWith('/organization/access')): articleid = '1109'; break;
            case (location.startsWith('/organization/branding')): articleid = '1099'; break;
            //services
            case (location.startsWith('/services/configuration')): {
                switch (storeHelpArticleId) {
                    case 'configuration': articleid = '1128'; break;
                    case 'settings': articleid = '1127'; break;
                    case 'help': articleid = '1123'; break;
                    case 'stores': articleid = '1026'; break;
                    default: articleid = '1120'; break;
                }
            } break;
            case (location.startsWith('/services/print')): articleid = '1114'; break;
            case (location.startsWith('/services/catalogs')): articleid = '1112'; break;
            case (location.startsWith('/services/promotions')): articleid = '1110'; break;
            case (location.startsWith('/services/selfservicepromotions')): articleid = '1014'; break;
            case (location.startsWith('/settings')): articleid = '1105'; break;
            
            default: articleid = '-100';
        }
    }
    
    const href = `/docs/default.htm#cshid=${articleid}`;

    return (
        <Stack horizontal grow horizontalAlign={props.alignment ? "start" : "end"} style={{
            flexDirection: "row",
            alignItems: "end",
            marginLeft: 'auto',
            marginRight: '10px',
            fontSize: 14,
            color: SharedColors.cyanBlue10
        }}>
            <Icon iconName='UnknownSolid'/>
            <Link href={href} target="_blank" style={{textDecoration: "underline", paddingLeft: 5}}>{props.label || strings.NAVIGATION.HELP.TITLE}</Link>
        </Stack>
    )

    
}