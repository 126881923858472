import * as React from 'react'
import { Pivot, PivotItem, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { strings } from '../../../localization/strings';
import { ServiceConfigurationPivotBasedState } from './ServiceConfigurationDetails/ServiceConfigurationPivotBasedState';
import { IService } from '../../../core/store';
import { IRequestResult } from '../../../core/store/typings/IResponseResult';
import { useDispatch } from 'react-redux';
import { actionCreators as helpActionCreators } from "../../../core/actions/help-actions";

type TProps = {
    data: IService
    onDelete: () => void
    retryInfo: () => void
    setInformation: (information: string) => void
    onImportWorkflow: () => void
    updateService: (data: IService) => Promise<IRequestResult<IService>>
    isFetching: boolean
    infoFetching: boolean
    resetInfoWasSaved: () => void
    infoWasSaved: boolean
    generateIcon?: boolean
}

export type TServicePivotState = "information" | "settings" | "configuration" | "help" | "stores"

const getTabId = (itemKey: string) => {
    return `ServicePivot_${itemKey}`;
};

const getPivotHeaderText = (m: string): string => {
    switch (m) {
        case "information": return strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.INFORMATION.TITLE
        case "settings": return strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.OPTIONS.TITLE
        case "configuration": return strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.CONFIGURATION
        case "help": return strings.ORGANIZATION.SERVICES.SERVICE.PIVOTS.HELP.TITLE
        case "stores": return strings.ORGANIZATION.STRUCTURE.ITEM.PIVOTS.POINTS
        default: return m;
    }
}



export const ServiceConfigurationDetails: React.FC<TProps> = (props) => {

    const dispatch = useDispatch();

    const [selectedPivotKey, setSelectedPivotKey] = React.useState<TServicePivotState>("information");
    
    // if (selectedPivotKey != 'information')
    // dispatch(helpActionCreators.setHelpArticleId('information11'));

    const handlePivotLinkClick = (item?: PivotItem) => {
        setSelectedPivotKey(item?.props.itemKey! as TServicePivotState);
        //dispatch new help article id
        dispatch(helpActionCreators.setHelpArticleId(item?.props?.itemKey ?? 'no data'));

    };

    return (
        <Stack verticalFill>
            <Stack.Item>
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <Stack tokens={{ padding: "0px 32px 0px 32px" }}>
                        <Stack.Item>
                            <Pivot selectedKey={selectedPivotKey} onLinkClick={handlePivotLinkClick} headersOnly={true} getTabId={getTabId} overflowBehavior="menu">
                                <PivotItem itemKey={"information"} headerText={getPivotHeaderText("information")} />
                                <PivotItem itemKey={"configuration"} headerText={getPivotHeaderText("configuration")} />
                                <PivotItem itemKey={"settings"} headerText={getPivotHeaderText("settings")} />
                                <PivotItem itemKey={"stores"} headerText={getPivotHeaderText("stores")} />
                                <PivotItem itemKey={"help"} headerText={getPivotHeaderText("help")} />
                            </Pivot>
                        </Stack.Item>
                    </Stack>
                </Sticky>
            </Stack.Item>
            <Stack.Item verticalFill>
                <Stack verticalFill tokens={{ childrenGap: 16 }}>
                    <ServiceConfigurationPivotBasedState
                        state={selectedPivotKey}
                        data={props.data}
                        onDelete={props.onDelete}
                        retryInfo={props.retryInfo}
                        setInformation={props.setInformation}
                        onImportWorkflow={props.onImportWorkflow}
                        updateService={props.updateService}
                        isFetching={props.isFetching}
                        infoFetching={props.infoFetching}
                        resetInfoWasSaved={props.resetInfoWasSaved}
                        infoWasSaved={props.infoWasSaved}
                        generateIcon={props.generateIcon}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
