import { 
    DirectionalHint, getTheme, IButtonStyles, 
    ICalloutContentStyles, ICalloutProps, IconButton, 
    TooltipDelay, TooltipHost 
} from "@fluentui/react";
import { useId } from "@uifabric/react-hooks";
import React from "react";

type TProps = {
    content: string
    tooltipDelay?: TooltipDelay
    tooltipCalloutProps?: ICalloutProps
    buttonStyles?: IButtonStyles
    disabled?: boolean
    iconName: string
    onClick?: () => void
}

export const TooltipIconButton: React.FC<TProps> = (props) => {
    const tooltipId = useId('tooltip');

    const onClickHandler = () => {
        if (props.onClick)
            props.onClick()
    }

    return (
        <TooltipHost
            calloutProps={{ styles: { ...tooltipCalloutStyles }, ...props.tooltipCalloutProps }}
            content={props.content}
            delay={props.tooltipDelay ?? TooltipDelay.medium}
            id={tooltipId}
            directionalHint={DirectionalHint.bottomCenter}
        >
            <div style={{display: 'inline'}} onClick={onClickHandler}>
                {props.children || (
                    <IconButton disabled={props.disabled} iconProps={{ iconName: props.iconName }} styles={props.buttonStyles} ariaLabel={props.iconName} />
                )}
            </div>
        </TooltipHost>
    )
}

const theme = getTheme();

const tooltipCalloutStyles: Partial<ICalloutContentStyles> = {
    beakCurtain: { background: theme.palette.neutralLight },
    beak: { background: theme.palette.neutralLight },
    calloutMain: { background: theme.palette.neutralLight },
    root: { color: theme.palette.white },
    container: { color: 'white' }
}