import { TNewAccountPassword } from "../../screens/userSettings/UserSettings";
import { ICatalog, ICatalogItem, ICatalogItemPrice, ICatalogVersion, ICompany, IContainer, IDocument, IDocumentVersion, IPerson, IPoint, IService, IWorkflow } from "../store";
import { IKnownDevice, IApplicationOptions, IOrganization, IResponseError, ISelfServicePromotion } from "@piceasoft/core";
import { IBranding } from "../store/typings/IBranding";
import { ICatalogProtocol } from "../store/typings/ICatalogProtocol";
import { INewOrganization } from "../store/typings/INewOrganization";
import { IPromotion } from "../store/typings/IPromotion";
import { IPromotionItem } from "../store/typings/IPromotionItem";
import { IRequestResult } from "../store/typings/IResponseResult";
import { IResponsiblePerson } from "../store/typings/IResponsiblePerson";
import { IStructureNode } from "../store/typings/IStructureNode";
import { IStructureProtocol } from "../store/typings/IStructureProtocol";
import { ISupervisor } from "../store/typings/ISupervisor";
import { ResponsibleAreas } from "../store/typings/ResponsibleAreas";
import { endpoints } from "./endpoints";
import { IPiceaCustomer } from "../store/typings/IPiceaCustomer"
import { IPiceaProduct} from "../store/typings/IPiceaProduct"
import { IPiceaCredentials } from "../store/typings/IPiceaCredentials";
import { IWorkflowStore } from "../store/typings/IWorkflowStore";

export const organizationApi = {
    getCustomers: async (): Promise<IRequestResult<IPiceaCustomer[]>> => {
        let result: IRequestResult<IPiceaCustomer[]> = { successed: false };
        await fetch(endpoints.v1.organization.customers(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaCustomer[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    getCustomerProducts: async (customer_id: string): Promise<IRequestResult<IPiceaProduct[]>> => {
        let result: IRequestResult<IPiceaProduct[]> = { successed: false };
        await fetch(endpoints.v1.organization.products(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({customer_id:customer_id})
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaProduct[]>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    searchCustomerId: async (customer_id: string): Promise<IRequestResult<IPiceaCredentials>> => {
        let result: IRequestResult<IPiceaCredentials> = { successed: false };
        await fetch(endpoints.v1.organization.searchCustomerId(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({customer_id:customer_id})
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaCredentials>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    searchCustomerVat: async (vat_number: string): Promise<IRequestResult<IPiceaCredentials>> => {
        let result: IRequestResult<IPiceaCredentials> = { successed: false };
        await fetch(endpoints.v1.organization.searchCustomerVat(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({vat_number:vat_number})
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IPiceaCredentials>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    create: async (data: INewOrganization): Promise<IRequestResult<IOrganization>> => {
        let result: IRequestResult<IOrganization> = { successed: false };

        await fetch(endpoints.v1.organization.organization(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(async response => {
                await (response.json() as Promise<IRequestResult<IOrganization>>)
                    .then(response => result = response)
                    .catch(error => result.errors = [{ description: error.message }])
            })
            .catch(error => result.errors = [{ description: error.message }])
        return result;
    },
    devices: {
        getDevices: async (): Promise<IRequestResult<IKnownDevice[]>> => {
            let result: IRequestResult<IKnownDevice[]> = { successed: false };
            await fetch(endpoints.v1.organization.devices.devices)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IKnownDevice[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getManufacturers: async (): Promise<IRequestResult<string[]>> => {
            let result: IRequestResult<string[]> = { successed: false };
            await fetch(endpoints.v1.organization.devices.manufacturers)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<string[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getModels: async (manufacturer: string): Promise<IRequestResult<string[]>> => {
            let result: IRequestResult<string[]> = { successed: false };
            await fetch(endpoints.v1.organization.devices.models(manufacturer))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<string[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getConfigurations: async (manufacturer: string, model: string): Promise<IRequestResult<string[]>> => {
            let result: IRequestResult<string[]> = { successed: false };
            await fetch(endpoints.v1.organization.devices.configurations(manufacturer, model))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<string[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        }
    },
    containers: {
        getContainers: async (): Promise<IRequestResult<IStructureNode[]>> => {
            let result: IRequestResult<IStructureNode[]> = { successed: false };
            await fetch(endpoints.v1.organization.containers)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IStructureNode[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getContainer: async (containerId: string): Promise<IRequestResult<IContainer>> => {
            let result: IRequestResult<IContainer> = { successed: false };
            await fetch(endpoints.v1.organization.container(containerId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IContainer>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addContainer: async (container: IContainer): Promise<IRequestResult<IContainer>> => {
            let result: IRequestResult<IContainer> = { successed: false };
            await fetch(endpoints.v1.organization.containers, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(container)
            })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<IContainer>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateContainer: async (container: IContainer): Promise<IRequestResult<IContainer>> => {
            let result: IRequestResult<IContainer> = { successed: false };
            await fetch(endpoints.v1.organization.containers, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(container)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IContainer>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteContainer: async (containerId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.container(containerId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateContainerInformation: async (containerId: string, information: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.containerInformation(containerId), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ information: information })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleTurnOnOptions: async (containerId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.containersToggleOptions(containerId), { method: 'PATCH' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateOptions: async (containerId: string, options: IApplicationOptions): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            console.log(options)
            await fetch(endpoints.v1.organization.containersUpdateOptions(containerId), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    catalogs: {
        getCatalogs: async (): Promise<IRequestResult<ICatalog[]>> => {
            let result: IRequestResult<ICatalog[]> = { successed: false };
            await fetch(endpoints.v1.organization.catalogs)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalog[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCatalogsByCatalogType: async (catalogType: number): Promise<IRequestResult<ICatalog[]>> => {
            let result: IRequestResult<ICatalog[]> = { successed: false };
            await fetch(endpoints.v1.organization.catalogsByType(catalogType))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalog[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCatalog: async (catalogId: string): Promise<IRequestResult<ICatalog>> => {
            let result: IRequestResult<ICatalog> = { successed: false };
            await fetch(endpoints.v1.organization.catalog(catalogId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalog>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getComponentsByCatalogId:async (catalogId: string): Promise<IRequestResult<ICatalogItemPrice[]>> => {
            let result: IRequestResult<ICatalogItemPrice[]> = { successed: false };
            await fetch(endpoints.v1.organization.getComponentsByCatalogId(catalogId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalogItemPrice[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addCatalog: async (catalog: ICatalog): Promise<IRequestResult<ICatalog>> => {
            let result: IRequestResult<ICatalog> = { successed: false };
            await fetch(endpoints.v1.organization.catalogs, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(catalog)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalog>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateCatalog: async (catalog: ICatalog): Promise<IRequestResult<ICatalog>> => {
            let result: IRequestResult<ICatalog> = { successed: false };
            await fetch(endpoints.v1.organization.catalogs, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(catalog)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalog>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteCatalog: async (catalogId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.catalog(catalogId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCatalogVersions: async (catalogId: string): Promise<IRequestResult<ICatalogVersion[]>> => {
            let result: IRequestResult<ICatalogVersion[]> = { successed: false };
            await fetch(endpoints.v1.organization.catalogVersions(catalogId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalogVersion[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        importCatalogVersion: async (catalogVersion: ICatalogVersion, file: File, setResponse: (response: IRequestResult<undefined>) => void, setProgress?: (progress: number) => void) => {
            let result: IRequestResult<undefined> = { successed: false };
            let body = new FormData()
            const error: IResponseError = { description: 'Bad response from server' }
            result.errors = [error]

            body.append('importFile', file)
            body.append('catalogVersion', JSON.stringify(catalogVersion))

            let xhr = new XMLHttpRequest()
            xhr.upload.onprogress = function (event) {
                setProgress && setProgress(event.loaded / event.total)
            }
            xhr.responseType = 'json';
            xhr.onload = function () {
                if (xhr.status == 200) {
                    result = xhr.response;
                }
                setResponse(result);
            }
            xhr.open("POST", endpoints.v1.organization.catalogImport)
            xhr.send(body)
        },
        getCatalogVersionItems: async (catalogId: string, catalogVersionId: string): Promise<IRequestResult<ICatalogItem[]>> => {
            let result: IRequestResult<ICatalogItem[]> = { successed: false };
            await fetch(endpoints.v1.organization.catalogVersionItems(catalogId, catalogVersionId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalogItem[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCatalogItems: async (catalogId: string): Promise<IRequestResult<ICatalogItem[]>> => {
            let result: IRequestResult<ICatalogItem[]> = { successed: false };
            await fetch(endpoints.v1.organization.catalogItems(catalogId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalogItem[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addCatalogVersion: async (catalog: ICatalogVersion): Promise<IRequestResult<ICatalogVersion>> => {
            let result: IRequestResult<ICatalogVersion> = { successed: false };
            await fetch(endpoints.v1.organization.catalogVersion, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(catalog)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalogVersion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCatalogProtocols: async (catalogId: string): Promise<IRequestResult<ICatalogProtocol[]>> => {
            let result: IRequestResult<ICatalogProtocol[]> = { successed: false };
            await fetch(endpoints.v1.organization.catalogProtocols(catalogId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICatalogProtocol[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    promotions: {
        getPromotions: async (): Promise<IRequestResult<IPromotion[]>> => {
            let result: IRequestResult<IPromotion[]> = { successed: false };
            await fetch(endpoints.v1.organization.promotions)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotion[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getPromotion: async (promotionId: string): Promise<IRequestResult<IPromotion>> => {
            let result: IRequestResult<IPromotion> = { successed: false };
            await fetch(endpoints.v1.organization.promotion(promotionId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addPromotion: async (promotion: IPromotion): Promise<IRequestResult<IPromotion>> => {
            let result: IRequestResult<IPromotion> = { successed: false };
            await fetch(endpoints.v1.organization.promotions, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(promotion)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updatePromotion: async (promotion: IPromotion): Promise<IRequestResult<IPromotion>> => {
            let result: IRequestResult<IPromotion> = { successed: false };
            await fetch(endpoints.v1.organization.promotions, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(promotion)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deletePromotion: async (promotionId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.promotion(promotionId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        }
    },
    promotionItems: {
        getPromotionItems: async (promotionId: string): Promise<IRequestResult<IPromotionItem[]>> => {
            let result: IRequestResult<IPromotionItem[]> = { successed: false };
            await fetch(endpoints.v1.organization.promotionItem(promotionId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotionItem[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getPromotionItem: async (promotionId: string, promotionItemId: string): Promise<IRequestResult<IPromotionItem>> => {
            let result: IRequestResult<IPromotionItem> = { successed: false };
            await fetch(endpoints.v1.organization.promotionItem(promotionId, promotionItemId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotionItem>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addPromotionItem: async (promotionId: string, promotionItem: IPromotionItem): Promise<IRequestResult<IPromotionItem>> => {
            let result: IRequestResult<IPromotionItem> = { successed: false };
            await fetch(endpoints.v1.organization.promotionItem(promotionId), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(promotionItem)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotionItem>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updatePromotionItem: async (promotionId: string, promotionItem: IPromotionItem): Promise<IRequestResult<IPromotionItem>> => {
            let result: IRequestResult<IPromotionItem> = { successed: false };
            await fetch(endpoints.v1.organization.promotionItem(promotionId), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(promotionItem)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPromotionItem>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deletePromotionItem: async (promotionId: string, promotionItemId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.promotionItem(promotionId, promotionItemId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        }
    },
    companies: {
        getCompanies: async (): Promise<IRequestResult<Array<ICompany>>> => {
            let result: IRequestResult<Array<ICompany>> = { successed: false };
            await fetch(endpoints.v1.organization.companies)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<Array<ICompany>>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCompany: async (companyId: string): Promise<IRequestResult<ICompany>> => {
            let result: IRequestResult<ICompany> = { successed: false };
            await fetch(endpoints.v1.organization.company(companyId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICompany>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addCompany: async (company: ICompany): Promise<IRequestResult<ICompany>> => {
            console.log(company)
            let result: IRequestResult<ICompany> = { successed: false };
            await fetch(endpoints.v1.organization.companies, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(company)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICompany>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateCompany: async (company: ICompany): Promise<IRequestResult<ICompany>> => {
            let result: IRequestResult<ICompany> = { successed: false };
            await fetch(endpoints.v1.organization.companies, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(company)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ICompany>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteCompany: async (companyId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.company(companyId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateCompanyInformation: async (companyId: string, information: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.companyInformation(companyId), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ information: information })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateOptions: async (companyId: string, options: IApplicationOptions): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.companyUpdateOptions(companyId), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    branding: {
        getBrandings: async (): Promise<IRequestResult<Array<IBranding>>> => {
            let result: IRequestResult<Array<IBranding>> = { successed: false };
            await fetch(endpoints.v1.organization.brandings)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<Array<IBranding>>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getBranding: async (id: number): Promise<IRequestResult<IBranding>> => {
            let result: IRequestResult<IBranding> = { successed: false };
            await fetch(endpoints.v1.organization.branding(id))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IBranding>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addBranding: async (branding: IBranding): Promise<IRequestResult<IBranding>> => {
            let result: IRequestResult<IBranding> = { successed: false };
            await fetch(endpoints.v1.organization.brandings, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(branding)
            })
                .then(async response => {
                    console.log(response)
                    await (response.json() as Promise<IRequestResult<IBranding>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateBranding: async (branding: IBranding): Promise<IRequestResult<IBranding>> => {
            let result: IRequestResult<IBranding> = { successed: false };
            await fetch(endpoints.v1.organization.brandings, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(branding)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IBranding>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteBranding: async (brandingId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.branding(brandingId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    services: {
        getServices: async (): Promise<IRequestResult<IService[]>> => {
            let result: IRequestResult<IService[]> = { successed: false };
            await fetch(endpoints.v1.organization.services())
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getCompanyServices: async (companyId: string): Promise<IRequestResult<IService[]>> => {
            let result: IRequestResult<IService[]> = { successed: false };
            await fetch(endpoints.v1.organization.companyServices(companyId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getService: async (serviceId: number): Promise<IRequestResult<IService>> => {
            let result: IRequestResult<IService> = { successed: false };
            await fetch(endpoints.v1.organization.service(serviceId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addService: async (service: IService): Promise<IRequestResult<IService>> => {
            let result: IRequestResult<IService> = { successed: false };
            await fetch(endpoints.v1.organization.services(), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(service)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateService: async (service: IService): Promise<IRequestResult<IService>> => {
            let result: IRequestResult<IService> = { successed: false };
            await fetch(endpoints.v1.organization.services(), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(service)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateServiceConfiguration: async (file: File, setResponse: (response: IRequestResult<undefined>) => void, setProgress?: (progress: number) => void, sendParams?: { [key: string]: number | string }) => {
            let result: IRequestResult<undefined> = { successed: false };
            let body = new FormData()
            body.append("file", file)

            let xhr = new XMLHttpRequest()
            xhr.upload.onprogress = function (event) {
                console.log(JSON.stringify(file.arrayBuffer()))
                console.log(`${event.total} ${event.loaded}`)
                setProgress && setProgress(event.loaded / event.total)
            }
            xhr.responseType = 'json';
            xhr.onload = function () {
                if (xhr.status == 200) {
                    result = xhr.response;
                }
                setResponse(result);
            }
            if (sendParams && sendParams["serviceId"]) {
                const serviceId = Number.parseInt(sendParams["serviceId"].toString())
                if (serviceId) {
                    xhr.open("POST", endpoints.v1.organization.serviceConfiguration(serviceId))
                } else {
                    setResponse({ successed: false, errors: [{ description: "Undefined service id" }] })
                }
                xhr.send(body)
            }
        },
        updateServiceConfig: async (workflow: IWorkflow, serviceId: number, isValid?: boolean) => {
            let result: IRequestResult<IWorkflow> = { successed: false };
            await fetch(endpoints.v1.organization.serviceConfig(serviceId, isValid), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(workflow)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IWorkflow>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateServiceInformation: async (serviceId: number, information: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.serviceInformation(serviceId), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ information: information })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteService: async (serviceId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.service(serviceId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleCompanyService: async (companyId: string, serviceId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.toggleCompanyService(companyId, serviceId), { method: 'PATCH' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getContainerServices: async (containerId: string): Promise<IRequestResult<IService[]>> => {
            let result: IRequestResult<IService[]> = { successed: false };
            await fetch(endpoints.v1.organization.containerServices(containerId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleUseContainerServices: async (containerId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.containerServices(containerId), { method: 'PATCH' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleContainerService: async (containerId: string, serviceId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.containerService(containerId, serviceId), { method: 'PATCH' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getPointServices: async (pointId: string): Promise<IRequestResult<IService[]>> => {
            let result: IRequestResult<IService[]> = { successed: false };
            await fetch(endpoints.v1.organization.pointServices(pointId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IService[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getServicePoints: async(serviceId: number): Promise<IRequestResult<IWorkflowStore[]>> => {
            let result: IRequestResult<IWorkflowStore[]> = {successed: false};
            await fetch(endpoints.v1.organization.servicePoints(serviceId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IWorkflowStore[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{description: error.message}])
                })
                .catch(error => result.errors = [{description: error.message}])
            return result;
        },
        toggleUsePointServices: async (pointId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.pointServices(pointId), { method: 'PATCH' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        togglePointService: async (pointId: string, serviceId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.pointService(pointId, serviceId), { method: 'PATCH' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        }
    },
    selfServicePromotion:{
        getPromotions: async (): Promise<IRequestResult<ISelfServicePromotion[]>> => {
            let result: IRequestResult<ISelfServicePromotion[]> = { successed: false };
            await fetch(endpoints.v1.organization.selfServicePromotion)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISelfServicePromotion[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getPromotion: async (id: number): Promise<IRequestResult<ISelfServicePromotion>> => {
            let result: IRequestResult<ISelfServicePromotion> = { successed: false };
            await fetch(endpoints.v1.organization.promotionById(id))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISelfServicePromotion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addPromotion: async (selfServicePromotion: ISelfServicePromotion): Promise<IRequestResult<ISelfServicePromotion>> => {
            let result: IRequestResult<ISelfServicePromotion> = { successed: false };
            await fetch(endpoints.v1.organization.selfServicePromotion, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(selfServicePromotion)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISelfServicePromotion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updatePromotion: async (selfServicePromotion: ISelfServicePromotion): Promise<IRequestResult<ISelfServicePromotion>> => {
            let result: IRequestResult<ISelfServicePromotion> = { successed: false };
            await fetch(endpoints.v1.organization.selfServicePromotion, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(selfServicePromotion)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISelfServicePromotion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deletePromotion: async (serviceId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.promotionById(serviceId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    documents: {
        getDocuments: async (): Promise<IRequestResult<IDocument[]>> => {
            let result: IRequestResult<IDocument[]> = { successed: false };
            await fetch(endpoints.v1.organization.documents)
                .then(async response => {
                    if (response.status === 200) {
                        await (response.json() as Promise<IRequestResult<IDocument[]>>)
                            .then(response => result = response)
                            .catch(error => result.errors = [{ description: error.message }])
                    }
                    else {
                        result.errors = [ {statusCode: response.status, description: "request failed"} ]
                    }
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getDocument: async (documentId: number): Promise<IRequestResult<IDocument>> => {
            let result: IRequestResult<IDocument> = { successed: false };
            await fetch(endpoints.v1.organization.document(documentId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocument>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addDocument: async (document: IDocument): Promise<IRequestResult<IDocument>> => {
            let result: IRequestResult<IDocument> = { successed: false };
            await fetch(endpoints.v1.organization.documents, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(document)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocument>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateDocument: async (document: IDocument): Promise<IRequestResult<IDocument>> => {
            let result: IRequestResult<IDocument> = { successed: false };
            await fetch(endpoints.v1.organization.documents, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(document)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocument>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteDocument: async (documentId: number): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.document(documentId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getDocumentVersions: async (documentId: number): Promise<IRequestResult<IDocumentVersion[]>> => {
            let result: IRequestResult<IDocumentVersion[]> = { successed: false };
            await fetch(endpoints.v1.organization.documentVersions(documentId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocumentVersion[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getDocumentVersion: async (documentId: number, versionId: number): Promise<IRequestResult<IDocumentVersion>> => {
            let result: IRequestResult<IDocumentVersion> = { successed: false };
            await fetch(endpoints.v1.organization.documentVersion(documentId, versionId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocumentVersion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addDocumentVersion: async (documentVersion: IDocumentVersion): Promise<IRequestResult<IDocumentVersion>> => {
            let result: IRequestResult<IDocumentVersion> = { successed: false };
            console.log(documentVersion)
            await fetch(endpoints.v1.organization.documentVersions(), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(documentVersion)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocumentVersion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateDocumentVersion: async (documentVersion: IDocumentVersion): Promise<IRequestResult<IDocumentVersion>> => {
            let result: IRequestResult<IDocumentVersion> = { successed: false };
            await fetch(endpoints.v1.organization.documentVersions(), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(documentVersion)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IDocumentVersion>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    points: {
        getPoints: async (companyId: string): Promise<IRequestResult<IPoint[]>> => {
            let result: IRequestResult<IPoint[]> = { successed: false };
            await fetch(endpoints.v1.organization.points(companyId))
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<IPoint[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getPoint: async (itemId: string): Promise<IRequestResult<IPoint>> => {
            let result: IRequestResult<IPoint> = { successed: false };
            await fetch(endpoints.v1.organization.points(undefined, itemId))
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<IPoint>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addPoint: async (item: IPoint, parentId?: string): Promise<IRequestResult<IPoint>> => {
            let result: IRequestResult<IPoint> = { successed: false };
            await fetch(endpoints.v1.organization.points(undefined, item.id), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item)
            })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<IPoint>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updatePoint: async (item: IPoint): Promise<IRequestResult<IPoint>> => {
            let result: IRequestResult<IPoint> = { successed: false };
            await fetch(endpoints.v1.organization.points(undefined, item.id), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item)
            })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<IPoint>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleEnabledPoint: async (itemId: string): Promise<IRequestResult<IPoint>> => {
            let result: IRequestResult<IPoint> = { successed: false };
            await fetch(endpoints.v1.organization.pointToggleEnabled(itemId), { method: 'PATCH' })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<IPoint>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deletePoint: async (itemId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.points(undefined, itemId), { method: 'DELETE' })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleTurnOnOptions: async (pointId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.pointsToggleOptions(pointId), { method: 'PATCH' })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateOptions: async (pointId: string, options: IApplicationOptions): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.pointsUpdateOptions(pointId), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options)
            })
                .then(async response => {
                    if (response.status === 500) {
                        result.errors = [{ description: "Bad response from server" }]
                        return result
                    }
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    responsiblePersons: {
        getResponsiblePersons: async (responsibleArea: ResponsibleAreas): Promise<IRequestResult<IResponsiblePerson[]>> => {
            let result: IRequestResult<IResponsiblePerson[]> = { successed: false };
            await fetch(endpoints.v1.organization.responsiblePersons(responsibleArea))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IResponsiblePerson[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addResponsiblePerson: async (person: IResponsiblePerson): Promise<IRequestResult<IResponsiblePerson>> => {
            let result: IRequestResult<IResponsiblePerson> = { successed: false };
            await fetch(endpoints.v1.organization.responsiblePersons(), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(person)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IResponsiblePerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateResponsiblePerson: async (person: IResponsiblePerson): Promise<IRequestResult<IResponsiblePerson>> => {
            let result: IRequestResult<IResponsiblePerson> = { successed: false };
            await fetch(endpoints.v1.organization.responsiblePersons(), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(person)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IResponsiblePerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteResponsiblePerson: async (responsiblePersonId: string, responsibleArea: ResponsibleAreas): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.responsiblePersons(responsibleArea, responsiblePersonId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    accounts: {
        getAccounts: async (organizationId?: string): Promise<IRequestResult<IPerson[]>> => {
            let result: IRequestResult<IPerson[]> = { successed: false };
            await fetch(endpoints.v1.organization.accounts(organizationId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getAccount: async (accountId: string): Promise<IRequestResult<IPerson>> => {
            let result: IRequestResult<IPerson> = { successed: false };
            await fetch(endpoints.v1.organization.accounts(undefined, accountId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addAccount: async (account: IPerson, parentId?: string): Promise<IRequestResult<IPerson>> => {
            let result: IRequestResult<IPerson> = { successed: false };
            console.log(JSON.stringify(account))
            await fetch(endpoints.v1.organization.accounts(), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(account)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson>>)
                        .then(response => { console.log(response); console.log(JSON.stringify(account)); result = response })
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateAccount: async (account: IPerson): Promise<IRequestResult<IPerson>> => {
            let result: IRequestResult<IPerson> = { successed: false };
            await fetch(endpoints.v1.organization.accounts(), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(account)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        toggleEnabledAccount: async (accountId: string): Promise<IRequestResult<IPerson>> => {
            let result: IRequestResult<IPerson> = { successed: false };
            await fetch(endpoints.v1.organization.accountToggleEnabled(accountId), {
                method: 'PATCH'
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteAccount: async (accountId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.accounts(undefined, accountId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        resetPasswordAccount: async (accountId: string, newPassword: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.resetPassword, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: newPassword, id: accountId })
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        sendCredentials: async (accountId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.sendCredentials(accountId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    profile: {
        updateProfile: async (profile: IPerson): Promise<IRequestResult<IPerson>> => {
            let result: IRequestResult<IPerson> = { successed: false };
            await fetch(endpoints.v1.organization.profile(), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(profile)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updatePassword: async (newAccountPassword: TNewAccountPassword): Promise<IRequestResult<IPerson>> => {
            let result: IRequestResult<IPerson> = { successed: false };
            await fetch(endpoints.v1.organization.profilePassword(), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newAccountPassword)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IPerson>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    structure: {
        import: async (file: File, setResponse: (response: IRequestResult<undefined>) => void, setProgress?: (progress: number) => void, callback?: () => void) => {
            let result: IRequestResult<undefined> = { successed: false };
            let body = new FormData()
            const error: IResponseError = { description: 'Bad response from server' }
            result.errors = [error]

            body.append('importFile', file)

            let xhr = new XMLHttpRequest()
            xhr.upload.onprogress = function (event) {
                setProgress && setProgress(event.loaded / event.total)
            }
            xhr.responseType = 'json';
            xhr.onload = function () {
                if (xhr.status == 200) {
                    result = xhr.response;
                }
                setResponse(result);
                callback && callback()
            }
            xhr.open("POST", endpoints.v1.importStructure)
            xhr.send(body)
        },
        getStructureProtocols: async (organizationId: string): Promise<IRequestResult<IStructureProtocol[]>> => {
            let result: IRequestResult<IStructureProtocol[]> = { successed: false };
            await fetch(endpoints.v1.structureProtocols(organizationId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<IStructureProtocol[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    help: {
        getHelp: async (): Promise<IRequestResult<string>> => {
            let result: IRequestResult<string> = { successed: false };
            await fetch(endpoints.v1.organization.help)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<string>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        setHelp: async (text: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.help, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: text
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    },
    configurator: {
        uploadFile: async (container: string, file: File, setResponse: (response: IRequestResult<string>) => void, setProgress?: (progress: number) => void, noPreview?: boolean) => {
            let result: IRequestResult<string> = { successed: false };
            let body = new FormData()
            const error: IResponseError = { description: 'Bad response from server' }
            result.errors = [error]

            body.append('uploadFile', file)

            let xhr = new XMLHttpRequest()
            xhr.upload.onprogress = function (event) {
                setProgress && setProgress(event.loaded / event.total)
            }
            xhr.responseType = 'json';
            xhr.onload = function () {
                if (xhr.status == 200) {
                    result = xhr.response;
                }
                setResponse(result);
            }
            xhr.open("POST", endpoints.v1.uploadFile(container, noPreview))
            xhr.send(body)
        }
    },
    supervisors: {
        getSupervisor: async (supervisorId: string): Promise<IRequestResult<ISupervisor>> => {
            let result: IRequestResult<ISupervisor> = { successed: false };
            await fetch(endpoints.v1.organization.supervisor(supervisorId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISupervisor>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getSupervisors: async (): Promise<IRequestResult<ISupervisor[]>> => {
            let result: IRequestResult<ISupervisor[]> = { successed: false };
            await fetch(endpoints.v1.organization.supervisors)
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISupervisor[]>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addSupervisor: async (person: ISupervisor): Promise<IRequestResult<ISupervisor>> => {
            let result: IRequestResult<ISupervisor> = { successed: false };
            await fetch(endpoints.v1.organization.supervisors, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(person)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISupervisor>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        updateSupervisor: async (person: ISupervisor): Promise<IRequestResult<ISupervisor>> => {
            let result: IRequestResult<ISupervisor> = { successed: false };
            await fetch(endpoints.v1.organization.supervisors, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(person)
            })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<ISupervisor>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteSupervisor: async (supervisorId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.supervisor(supervisorId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        getSupervisorOrganizations: async (supervisorId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.supervisorOrganizations(supervisorId))
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        addSupervisorOrganization: async (supervisorId: string, organizationId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.supervisorOrganization(supervisorId, organizationId), { method: 'POST' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
        deleteSupervisorOrganization: async (supervisorId: string, organizationId: string): Promise<IRequestResult<undefined>> => {
            let result: IRequestResult<undefined> = { successed: false };
            await fetch(endpoints.v1.organization.supervisorOrganization(supervisorId, organizationId), { method: 'DELETE' })
                .then(async response => {
                    await (response.json() as Promise<IRequestResult<undefined>>)
                        .then(response => result = response)
                        .catch(error => result.errors = [{ description: error.message }])
                })
                .catch(error => result.errors = [{ description: error.message }])
            return result;
        },
    }
}