import { Action, Reducer } from "redux";
import { getAllowedNavigation } from "../../routers";
import { KnownAction } from "../actions/environment-actions";
import { IEnvironment } from "../store";

export const reducer: Reducer<IEnvironment> = (state: IEnvironment | undefined, incomingAction: Action): IEnvironment => {
    if (state === undefined) {
        return {
            name: "unknown",
            version: "unknown",
            isAuthenticated: false,
            isReady: false,
            lang: "en",
            culture: "en",
            applicationUrl: "",
            selfServiceUrl: ""
        };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ENVIRONMENT_INITIALIZATION_REQUEST':
            return { ...state, isReady: false };
        case 'ENVIRONMENT_INITIALIZATION_IS_READY':
            let navigation: Array<string> = []
            if (action.configuration.roles) {
                navigation = getAllowedNavigation(action.configuration.roles).map(i => i.path);
            }
            return { ...action.configuration, navigation: navigation, isReady: true };
        default: return state;
    }
};
