import React from "react";
import {
    Checkbox, getTheme,
    Pivot,
    PivotItem,
    PrimaryButton,
    Stack,
    Text, TextField,
} from "@fluentui/react";
import { CopyToClipBoardButton } from "../../../../components/shared/buttons/CopyToClipboardButton";
import { WorkflowQrCodeItem } from "../../../../components/shared/qrCode/WorkflowQrCodeItem";
import { ComingSoon } from "../../../../components/shared/comingSoon/ComingSoon";
import { endpoints } from "../../../../core/api/endpoints";
import { strings } from "../../../../localization/strings";

type TProps ={
    qrCodeLink: string
}

export const ShareWorkflowDialog: React.FC<TProps> = ({qrCodeLink}) =>{
    const [downloadItems, setDownloadItems] = React.useState( {SVG:true, PDF:true, PNG:true })
    const qrCodeServiceUrl = `https://api-rd.piceasoft.com/online/v1/qrcode?data=${encodeURIComponent(qrCodeLink)}`
    const embedScriptText = `<iframe style='border:1px solid rgba(0,0,0,0.1)' width='800' height='450' src='${qrCodeServiceUrl}' allowfullscreen ></iframe>`

    const onQrCodeDownloadHandler = () => {
        let formats = 0
        
        if (downloadItems.SVG)
            formats += 1
        
        if (downloadItems.PNG)
            formats += 2
        
        if (downloadItems.PDF)
            formats += 4
        
        if (formats > 0)        
            location.href = endpoints.v1.downloadQrCodes(qrCodeLink, formats);
    }
    return (
        <Pivot>
            <PivotItem headerText={strings.COMMON.SHARE}>
                <Stack tokens={{ padding: "24px 10px", childrenGap: 20 }} >
                    <WorkflowQrCodeItem qrCodeLink={qrCodeLink} />
                    <Stack verticalAlign={"center"} horizontalAlign={"end"}>
                        <CopyToClipBoardButton  content={qrCodeLink} >
                            <PrimaryButton text={strings.BUTTONS.TEXT.COPY_LINK} iconProps={{iconName:"Link"}} />
                        </CopyToClipBoardButton>
                    </Stack>
                </Stack>
            </PivotItem>
            <PivotItem headerText={strings.BUTTONS.TEXT.DOWNLOAD}>
                <Stack  verticalFill tokens={{ padding: "24px 10px", childrenGap: 20 }} >
                    <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 500 }}>
                        {strings.ORGANIZATION.SERVICES.SERVICE.DOWNLOAD_QR_CODE}
                    </Text>

                    <Checkbox label="SVG" checked={downloadItems.SVG}
                              onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => { 
                                  setDownloadItems({...downloadItems, SVG: isChecked as boolean}) }} />

                    <Checkbox label="PNG"  checked={downloadItems.PNG}
                              onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => { 
                                  setDownloadItems({...downloadItems, PNG: isChecked as boolean}) }}/>

                    <Checkbox label="PDF" checked={downloadItems.PDF}
                              onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => { 
                                  setDownloadItems({...downloadItems, PDF: isChecked as boolean}) }}/>
                </Stack>
                <Stack horizontalAlign={"end"}>
                    <PrimaryButton text={strings.BUTTONS.TEXT.DOWNLOAD} disabled={ !downloadItems.SVG && !downloadItems.PNG && !downloadItems.PDF } 
                                   iconProps={{iconName:"Download"}} 
                                   onClick={ onQrCodeDownloadHandler }/>
                </Stack>
            </PivotItem>
            <PivotItem headerText={strings.COMMON.EMBED}>
                <ComingSoon />
                {/* <Stack  verticalFill tokens={{ padding: "24px 10px", childrenGap: 20 }} >
                    <Text variant="medium" style={{ color: theme.palette.black, fontWeight: 500 }}>
                        {strings.ORGANIZATION.SERVICES.SERVICE.EMBED_QR_CODE}
                    </Text>
                    <TextField resizable={false} disabled multiline rows={5} defaultValue={embedScriptText} />
                </Stack>
                <Stack horizontalAlign={"end"}>
                    <CopyToClipBoardButton  content={embedScriptText} >
                        <PrimaryButton text={strings.BUTTONS.TEXT.COPY_EMBED_CODE} iconProps={{iconName:"Copy"}}/>
                    </CopyToClipBoardButton>
                </Stack> */}
            </PivotItem>
        </Pivot>
    )
}

const theme = getTheme();

